import { ExclamationTriangleIcon, RocketIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "ui/components/primitives/alert";
import { ShineBorder } from "ui/components/shine-border";
import { captureEvent } from "ui/lib/captureEvent";
import { validateEmail } from "ui/lib/helpers";

export const WaitlistEmailCapture = ({
  showDescription = true,
  teamName,
  redirectURL,
  waitlistZapierWebhookURL,
}) => {
  const [value, setValue] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const isValidEmail = validateEmail(value);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidEmail) return;

    setSubmitting(true);
    setError(null);
    setSuccess(false);

    captureEvent({
      action: "CTA Clicked",
      category: "coach_page_waitlist",
      label: showDescription ? "hero" : "footer",
      value: "CTA Clicked",
      teamName: teamName,
    });

    const data = [
      {
        email: e.target.email.value,
        name: teamName,
      },
    ];

    const JSONdata = JSON.stringify(data);

    try {
      const options = {
        method: "POST",
        body: JSONdata,
      };

      const response = await fetch(waitlistZapierWebhookURL, options);

      if (!response.ok) {
        throw new Error("Failed to submit the form");
      }

      const result = await response.json();

      setSuccess(true);

      if (redirectURL) {
        window.location.href = redirectURL;
      }
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const [isFocused, setIsFocused] = useState(false);

  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto lg:mx-0 w-full">
      <div className="relative mb-3 w-full">
        <ShineBorder
          color={isFocused ? "#000000" : ["#e6ff03"]}
          className="p-[3px] w-full border"
          borderRadius={100}
          borderWidth={2}
          duration={9}
        >
          <input
            id="email"
            type="email"
            style={{
              backgroundImage: `linear-gradient(44deg, #3d3d3d, #282828, #8b9908)`,
            }}
            className="rounded-full bg-input border text-white px-4 pr-[10rem] py-4 w-full text-lg focus:ring-primary"
            placeholder="Add email to join waitlist"
            disabled={submitting}
            onChange={(e) => setValue(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            value={value}
          />
          <div className="absolute right-0 top-0 p-1 flex h-full">
            <button
              type="submit"
              className="bg-primary text-primary-foreground rounded-full px-4 py-2 cta"
              disabled={submitting || !isValidEmail}
            >
              {submitting ? "RESERVING..." : "RESERVE SPOT"}
            </button>
          </div>
        </ShineBorder>
      </div>
      {showDescription && !success && !error && (
        <p className="text-center text-sm lg:text-base text-primary">
          No Credit Card Required • 100% Risk-Free
        </p>
      )}
      {error && (
        <Alert variant="destructive">
          <ExclamationTriangleIcon className="h-4 w-4" />
          <AlertTitle>Something went wrong!</AlertTitle>
          <AlertDescription>Please Refresh and try again.</AlertDescription>
        </Alert>
      )}
      {success && (
        <Alert variant="default">
          <RocketIcon className="h-4 w-4" />
          <AlertTitle>Success!</AlertTitle>
          <AlertDescription>
            Thank you for joining the waitlist!
          </AlertDescription>
        </Alert>
      )}
    </form>
  );
};
